import React from "react";
import { Title } from "../../components/Title/Title";
import { Paragraph } from "../../components/Paragraph/Paragraph";
import "./History.css"
import historyPic from '../../vendor/images/history.png'

export const History = () => {
  return (
    <section className="section">
      <div className="historyText">
        <Title>Наша история</Title>
        <Paragraph>
          Наши отношения начались в 2019 году со случайной встречи в МГУ.
        </Paragraph>
        <Paragraph>
          Никто из нас и не предполагал, что скоро мы пригласим вас на нашу
          свадьбу.
        </Paragraph>
        <Paragraph>
          Но одно можем сказать точно, мы безумно благодарны судьбе за эту
          встречу!
        </Paragraph>
      </div>
      <div className="historyPic">
        <img className="historyImage" src={historyPic} alt="historyPic" />
        <Paragraph className={"infinity"}>
        26.04.2019 - ∞
        </Paragraph>
      </div>
    </section>
  );
};
