import React from 'react'
import { Title } from "../../components/Title/Title";
import { Paragraph } from "../../components/Paragraph/Paragraph";
import { YMaps, Map,Placemark } from "react-yandex-maps";

export const Directions = () => {
  return (
    <section className="section">
    <div className="historyText">
      <Title>Как добраться?</Title>
      <Paragraph>Адрес: Рублёво-Успенское шоссе, 2, село Усово, Одинцовский городской округ, Московская область</Paragraph>
<Paragraph>Маршрут для навигатора: <a href='https://yandex.ru/maps/-/CCUkJYD3lD' target="_blank" rel="noreferrer">Построить маршрут</a></Paragraph>
    </div>
    <YMaps>
    <div style={{textAlign:'center', margin: '0 auto'}}>
      <Map height={350} defaultState={{ center: [55.731694, 37.219349], zoom: 9, controls: ["zoomControl", "fullscreenControl"], }} modules={["control.ZoomControl", "control.FullscreenControl"]}><Placemark modules={["geoObject.addon.balloon"]} defaultGeometry={[55.731694, 37.219349]} properties={{
        balloonContentBody:
          "<a href='https://yandex.ru/maps/-/CCUkJYD3lD' target='_blank' rel='noreferrer'>Шале Березка - Построить маршрут</a>",
      }}/></Map>
    </div>
  </YMaps>
  </section>
  )
}
