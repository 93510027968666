import { Routes, Route } from "react-router-dom";
import "./App.css";
import { History } from "./pages/History/History";
import { Home } from "./pages/Home/Home";
import { Menu } from "./components/Menu/Menu";
import { Invite } from "./pages/Invite/Invite";
import { Program } from "./pages/Program/Program";
import { Dresscode } from "./pages/Dresscode/Dresscode";
import { Poll } from "./pages/Poll/Poll";
import { Directions } from "./pages/Directions/Directions";
import { Contacts } from "./pages/Contacts/Contacts";

function App() {
  return (
    <div className="page">
      <Menu />
      <div className="container">
        <Routes>
          <Route path="*" element={<Home />} />
          <Route path="/" element={<Home />} />
          <Route path="/history" element={<History />} />
          <Route path="/invite" element={<Invite />} />
          <Route path="/program" element={<Program />} />
          <Route path="/dresscode" element={<Dresscode />} />
          <Route path="/poll" element={<Poll />} />
          <Route path="/directions" element={<Directions />} />
          <Route path="/contacts" element={<Contacts />} />
        </Routes>
      </div>
      {/* <p>#romalina.wedding</p> */}
    </div>
  );
}

export default App;
