import React from "react";
import { Title } from "../../components/Title/Title";
import { Paragraph } from "../../components/Paragraph/Paragraph";
import dresscode from "../../vendor/images/dresscode.png"
import "./Dresscode.css"

export const Dresscode = () => {
  return (
    <section className="section dresscode">
      <div className="historyText">
        <Title>Dress Code</Title>
        <img src={dresscode} className="dresscodeImg" />
        <Paragraph>
          Нам будет приятно, если вы поддержите стилистику нашей свадьбы.
          Приветствуются темные костюмы на кавалерах и вечерние платья в
          пастельных тонах на дамах.
        </Paragraph>
        <img src={dresscode} className="dresscodeImg2" />
      </div>
    </section>
  );
};
