import React from "react";
import { Paragraph } from "../../components/Paragraph/Paragraph";
import "./Program.css";

export const Program = () => {
  return (
    <section className="section program">
      <div className="programText">
        <h1 className="programTitle">Дата — 26 августа 2023 г.</h1>
        <Paragraph className="programTextBold">17:00 — сбор гостей</Paragraph>
        <Paragraph>18:00 — торжественный банкет</Paragraph>
        <br />
        <Paragraph className="programTextBold">Место проведения</Paragraph>
        <Paragraph>Ресторан "Шале Берёзка"</Paragraph>
        <br />
        <Paragraph className="programTextBold">Адрес</Paragraph>
        <Paragraph>
          Московская область, Одинцовский городской округ, село Усово,
          Рублево-Успенское шоссе, д.2 БЦ "Резиденция на Рублевке"
        </Paragraph>
      </div>
    </section>
  );
};