import React, { useRef, useState } from "react";
import { Title } from "../../components/Title/Title";
import emailjs from "@emailjs/browser";
import "./Poll.css";
import envelope from "../../vendor/images/envelope.png"
import { Navigate } from "react-router-dom";

export const Poll = () => {
  const form = useRef();
  const [errorText, setErrorText] = useState("");
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_gah5j54",
        "template_2kc1jkx",
        form.current,
        "WifPaa3I-RgQMEIKA"
      )
      .then(
        (result) => {
          form.current.reset();
          console.log(result.text);
          if (window.confirm("Будем рады вас видеть. Заполнить ещё одну форму?")) {
          } else {
            document.location.href = '/directions'
          }
          
        },
        (error) => {
          setErrorText(error.text);
          form.current.reset();
          console.log(error.text);
        }
      );
  };
  return (
    <section className="section">
      <div className="historyText">
        <Title className="pollTitle">Подтвердите присутствие</Title>
        <div className="form">
          <p>
            Заполните, пожалуйста, форму, чтобы подтвердить свое присутствие.
          </p>
          <br />
          <form className="wedForm" ref={form} onSubmit={sendEmail}>
            <label>
              Имя и Фамилия (просьба заполнить форму на каждого гостя)
            </label>
            <input className="wedFormInput" name="user_name" type="text" required />
            <label>Номер телефона</label>
            <input className="wedFormInput" type="phone" name="user_phone" />
            <label>E-mail (для свадебных фото)</label>
            <input
              className="wedFormInput"
              type="email"
              name="user_email"
              required
            />
            <input
              className="WedFormButton"
              type="submit"
              value="Отправить"
              required
            />
            {errorText}
          </form>
        </div>
      </div>
        <img src={envelope} className="envelope" alt="envelope"/>
    </section>
  );
};
