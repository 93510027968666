import React from "react";
import Timer from "../../components/Timer/Timer";
import "./Home.css"
import watch from '../../vendor/images/watch.png'

export const Home = () => {
  return (
    <section className="home">
      <h1 className="homeTitle">
        <span>Роман</span>
        <span>&</span>
        <span>Алина</span>
      </h1>
      <div className="timer">
        <img className="watch" src={watch} alt="watch" />
        <Timer className={'counter'} deadline="August, 26, 2023" />
      </div>
    </section>
  );
};
