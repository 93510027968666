import React from 'react'
import { Title } from "../../components/Title/Title";
import { Paragraph } from "../../components/Paragraph/Paragraph";
import "./Invite.css"
import couple from '../../vendor/images/couple.png'

export const Invite = () => {
  return (
    <section className="section invite">
      <div className="historyText">
        <Title>Приглашение</Title>
        <Paragraph>
        Дорогие друзья,
        </Paragraph>
        <Paragraph>
        С огромной радостью мы делимся с вами новостями о нашей предстоящей свадьбе.
        <br />
26 августа мы соединимся узами брака, отметив начало нашего прекрасного семейного пути.
        </Paragraph>
        <Paragraph>
        Мы приглашаем присоединиться к нам в праздновании этого знаменательного события. Ваше присутствие в этот незабываемый день имело бы для нас большое значение.
        </Paragraph>
        <Paragraph>
        На свадьбе будет достаточно флористики, поэтому о цветах можно не беспокоиться.
        </Paragraph>
        <Paragraph>
        С любовью и ожиданием,
        <br />
Роман и Алина
        </Paragraph>
      </div>
      <img src={couple} className='couple' alt="couple" />
    </section>
  )
}
