import React from "react";
import { NavLink } from "react-router-dom";
import "./Menu.css";

export const Menu = () => {
  return (
    <nav>
      <ul className="menu">
      <li className="menuItem">
          <NavLink className="menuLink" to={'/'}>Главная</NavLink>
        </li>
        <li className="menuItem">
          <NavLink className="menuLink" to={'/history'}>История</NavLink>
        </li>
        <li className="menuItem">
          <NavLink className="menuLink" to={'/invite'}>Приглашение</NavLink>
        </li>
        <li className="menuItem">
          <NavLink className="menuLink" to={'/program'}>Программа</NavLink>
        </li>
        <li className="menuItem">
          <NavLink className="menuLink" to={'/dresscode'}>Dress Code</NavLink>
        </li>
        <li className="menuItem">
          <NavLink className="menuLink" to={'/poll'}>Опрос</NavLink>
        </li>
        <li className="menuItem">
          <NavLink className="menuLink" to={'/directions'}>Как добраться?</NavLink>
        </li>
        <li className="menuItem">
          <NavLink className="menuLink" to={'/contacts'}>Контакты</NavLink>
        </li>
      </ul>
    </nav>
  );
};
