import React from 'react'
import { Title } from "../../components/Title/Title";
import { Paragraph } from "../../components/Paragraph/Paragraph";
import "./Contacts.css"
import contacts from "../../vendor/images/contacts.png"
import rings from "../../vendor/images/rings.png"

export const Contacts = () => {
  return (
    <section className="section contacts">
        <Title className='contactsTitle'>Мы вас очень ждем и надеемся, что этот волшебный день надолго останется в вашей памяти!</Title>
        <img className='contactsImg' src={contacts} alt='contacts' />
      <div className="historyText">
        <Paragraph>
        На любые ваши вопросы 
с удовольствием ответит 
наш организатор:

        </Paragraph>
        <Paragraph>
        Влада <br />
        <a href='tel:+79774149848'>+7 (977) 414-98-48</a>
        </Paragraph>
        <Paragraph>
        С любовью, <br />
Роман и Алина
        </Paragraph>
      </div>
      <img className='contactsImg2' src={rings} alt='rings' />
    </section>
  )
}
